@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");

* {
  font-family: "Rubik", sans-serif;
}

.edit-trip-datetimepicker{
  color: ;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  width: 100%;
  height: 56px
}

.edit-trip-datetimepicker .react-datetime-picker__inputGroup {
  min-height: auto;
}

.edit-trip-datetimepicker .react-datetime-picker__button {
  padding: 8px;
}